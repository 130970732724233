import React from "react"
import PropTypes from "prop-types"
import { Link, StaticQuery, graphql } from "gatsby"

import Header from "./header"
import "./layout.css"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <>
        <Header siteTitle={data.site.siteMetadata.title} />
        <div class="gridthing">
          <main style={{ gridColumnStart: 2, gridColumnEnd: 3 }}>{children}</main>
          <footer style={{
            gridColumnStart: 2,
            gridColumnEnd: 3,
            textAlign: 'center',
            marginTop: '4rem'
          }}>
            <div>
              <Link to="/about" style={{ marginLeft: '1rem', marginRight: '1rem'}}>About</Link>
              {' '}
              <Link to="/privacy" style={{ marginLeft: '1rem', marginRight: '1rem'}}>Privacy</Link>
            </div>
            <small>
              Copyright &copy; {new Date().getFullYear()} Cogniworks Ltd
            </small>
          </footer>
        </div>
      </>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
